<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 회사 일괄 등록 시스템</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <input
          type="file"
          id="excelUpload"
          @change="importExcel"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </v-col>
    </v-row>
    <v-row v-if="items.length == 0">
      <v-col cols="12">
        엑셀에서 데이터가 식별되지 않습니다. <br />
        올바른 엑셀 데이터를 불러오십시오.</v-col
      >
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col cols="12">
        총 {{ items.length }} 개의 데이터가 식별되었습니다.
      </v-col>
      <v-col cols="12">
        <v-btn color="info" @click="uploadStart"> 업로드 실행 </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="uploadStarted">
      <v-col cols="12"> 업로드 진행중...! </v-col>
      <v-col cols="12">
        총 {{ success_item_cnt }} 개의 데이터를 업로드 하였습니다.
      </v-col>
      <v-col cols="12">
        총 {{ fail_item_cnt }} 개의 데이터가 실패되었습니다..
      </v-col>
    </v-row>
    <v-row v-if="uploadFinished">
      <v-col cols="12">
        <v-btn color="success" @click="downloadSuccess">
          성공데이터 다운로드
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn color="warning" @click="downloadFail">
          실패데이터 다운로드
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { newCompany } from "@/api/Admin/Company";
import * as XLSX from "xlsx";
export default {
  mounted() {},
  methods: {
    importExcel(event) {
      this.items = [];
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        console.log(e);
        let workbook = XLSX.read(data, { type: "binary" });
        workbook.SheetNames.forEach((sheetName) => {
          const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          this.items.push(...roa);
        });
      };
      reader.readAsBinaryString(file);
    },
    async uploadStart() {
      this.uploadStarted = true;
      for (const data of this.items) {
        await this.newCompany(data);
      }
      this.uploadFinished = true;
    },
    async newCompany(target) {
      const rsp = await newCompany(target);
      if (rsp.success) {
        this.success_items.push(rsp.data);
        this.success_item_cnt = this.success_items.length;
      } else {
        this.fail_items.push(target);
        this.fail_item_cnt = this.fail_items.length;
      }
    },
    async downloadSuccess() {
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(this.success_items);
      XLSX.utils.book_append_sheet(workBook, workSheet, "sheet1");
      XLSX.writeFile(workBook, `회사데이터업로드 성공목록-${Date.now()}.xlsx`);
    },
    async downloadFail() {
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(this.fail_items);
      XLSX.utils.book_append_sheet(workBook, workSheet, "sheet1");
      XLSX.writeFile(workBook, `회사데이터업로드 실패목록-${Date.now()}.xlsx`);
    },
  },
  data() {
    return {
      items: [],
      success_items: [],
      fail_items: [],
      success_item_cnt: 0,
      fail_item_cnt: 0,
      uploadStarted: false,
      uploadFinished: false,
    };
  },
};
</script>
