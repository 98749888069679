import { requests } from "@/utils/requests";

export const getCompany = async (company_uuid) => {
  let query = "";
  if (company_uuid != null) query = `company_uuid=${company_uuid}`;
  const data = await requests({
    url: `/api/admin/company?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getAllocUser = async (company_uuid) => {
  const data = await requests({
    url: `/api/admin/company/${company_uuid}/users`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delAllocUser = async (company_uuid, user_uuid) => {
  const data = await requests({
    url: `/api/admin/company/${company_uuid}/users/${user_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newAllocUser = async (company_uuid, user_uuid) => {
  const data = await requests({
    url: `/api/admin/company/${company_uuid}/users`,
    data: { user_uuid },
    method: "POST",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newCompany = async ({
  company_name,
  company_account_number,
  company_level,
  company_extends,
}) => {
  const data = await requests({
    url: "/api/admin/company",
    method: "POST",
    data: {
      company_name,
      company_account_number,
      company_level,
      company_extends,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putCompany = async (
  company_uuid,
  { company_name, company_account_number, company_level, company_extends }
) => {
  let query = "";
  if (company_uuid != null) query = `company_uuid=${company_uuid}`;
  const data = await requests({
    url: `/api/admin/company?${query}`,
    method: "PUT",
    data: {
      company_name,
      company_account_number,
      company_level,
      company_extends,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delCompany = async (company_uuid) => {
  let query = "";
  if (company_uuid != null) query = `company_uuid=${company_uuid}`;
  const data = await requests({
    url: `/api/admin/company?${query}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getCompany,
  getAllocUser,
  delAllocUser,
  newAllocUser,
  newCompany,
  putCompany,
  delCompany,
};
